import React from 'react';
import * as Logger from 'loglevel';
import { Switch, Route, Redirect } from 'react-router-dom'

import Story from '../main-content/arrivalboard/Story'
import Media from '../main-content/arrivalboard/Media'
import Users from '../main-content/admin/Users'
import ProductionCenter from '../main-content/admin/ProductionCenter'
import About from '../main-content/admin/About'
import Hub from '../main-content/admin/Hub/Hub'

import FormSelector from '../../widgets/FormSelector';
import { HubForm } from 'hub-dashboard-framework'
import * as HubConstant from "util/HubConstant"
import { UtilConstant } from 'hub-utilities'
import authService from '../../api-authorization/AuthorizeService';

import MetricsReports from '../main-content/arrivalboard/MetricsReports/MetricsReports'

import 'styles/sections/MainContent.scss';

class MainContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            toggle: false,
            windowWidth: 0,
            currentPage: '',
            sideNavToggled: false,
            breakWidth: 1400,
            formDescriptor: null
        };
    }

    onOpenForm = (formType, objectType, formObject) => {
        Logger.debug(formType)
        Logger.debug(objectType)
        Logger.debug(formObject)
        let formDesc = FormSelector.selectForm(formType, formObject);
        Logger.debug(formDesc);
        if (!!formDesc) {
            let newState = {
                ...this.state,
                formDescriptor: formDesc,
            }
            this.setState(newState);
        }
    }

    onFormClosed = () => {
        let newState = {
            ...this.state,
            formDescriptor: null,
        }
        this.setState(newState);
    }

    render() {
        Logger.debug(this.props);
        return (
            <div className="MainContent">
                <HubForm {...this.props} formDesc={this.state.formDescriptor} onFormClosed={this.onFormClosed} onFormSubmitted={this.onFormClosed} arrOfNewData={this.props.arrOfNewData} round />
                <Switch>
                    <Redirect exact from='/' to='/newsfeed' />
                    <Route path='/newsfeed' render={(props) => <Story {...props}
                        activePageName={this.props.activePageName} setActivePageName={this.props.setActivePageName} onOpenForm={this.onOpenForm} objectType={HubConstant.HUB_OBJECT_STORY}
                        clickNotifier={this.props.clickNotifier} arrOfNewData={this.props.arrOfNewData} setArrOfNewData={this.props.setArrOfNewData} isNotificationAllowed={this.props.isNotificationAllowed} setIsNotificationAllowed={this.props.setIsNotificationAllowed} />} />
                  <Route path='/usage_metrics' render={(props) => <MetricsReports {...props} hubInfo={authService.getHubInfo()} activePageName={this.props.activePageName} setActivePageName={this.props.setActivePageName} />} />
                    <Route path='/about' render={(props) => <About {...props} activePageName={this.props.activePageName} setActivePageName={this.props.setActivePageName} />} />
                    {
                        authService.getUserType() !== UtilConstant.HUB_USER_TYPE.HUB_USER ?
                            <>
                                <Route path='/users' render={(props) => <Users {...props} activePageName={this.props.activePageName} setActivePageName={this.props.setActivePageName} onOpenForm={this.onOpenForm} objectType={HubConstant.HUB_USERS} />} />
                                <Route path='/pdcenter' render={(props) => <ProductionCenter {...props} isForm={false} activePageName={this.props.activePageName} setActivePageName={this.props.setActivePageName} onOpenForm={this.onOpenForm} />} />
                                {
                                    authService.getUserType() === UtilConstant.HUB_USER_TYPE.HUB_ADMIN ?
                                        <Route path='/hub' render={(props) => <Hub {...props} activePageName={this.props.activePageName} setActivePageName={this.props.setActivePageName} objectType={HubConstant.HUB_PRODUCTION_GROUP} onOpenForm={this.onOpenForm} />} onFormClosed={this.onFormClosed} />
                                        : <></>
                                }
                            </>
                            : <></>
                    }
                </Switch>
            </div>
        );

    }
}

export default MainContent;