import React, { useState, useEffect, useRef } from "react";
import { TextField } from '@material-ui/core';
import * as Logger from 'loglevel';
import hubApi from 'util/HubApi';
import authorizeService from 'components/api-authorization/AuthorizeService';
import { UtilConstant } from 'hub-utilities';
import { HubUserFormEx } from 'hub-dashboard-framework';

import 'styles/widgets/User.scss';

export default function HubUserForm(props) {

    const user = props.payload;
    const [username, setUsername] = useState(!!user.FirstName ? user.LoginName.val : null);
    const [firstname, setFirstname] = useState(!!user.FirstName ? user.FirstName.val : null);
    const [lastname, setLastname] = useState(!!user.FirstName ? user.LastName.val : null);
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [email, setEmail] = useState(!!user.FirstName ? user.Email.val : null);
    const [phoneNumber, setPhoneNumber] = useState(!!user.FirstName ? user.phoneNumber : null);
    const [adminLevel, setAdminLevel] = useState(!!user.FirstName ? user.adminLevel : UtilConstant.HUB_USER_TYPE.HUB_USER);
    const [description, setDescription] = useState(!!user.FirstName ? user.Description.val : null);
    const [formError, setFormError] = useState(null);
    const [isStatusEnabled, setIsStatusEnabled] = useState(false);
    const [isCreatingUser, setIsCreatingUser] = useState(false);
    const [hubNodeId, setHubNodeId] = useState(!!user.FirstName ? user.ProductionCentre.id : authorizeService.getNodeIdByUserGuid(authorizeService.getUserGuid()));
    const [spaceWarningShow, setSpaceWarningShow] = useState(false);
    const spaceWarningTarget = useRef(null);



    const onChange = (event, isPassword) => {
        if (isPassword && event.target.name.includes("password") && event.target.value.includes(" ")) {
            setSpaceWarningShow(true);
        } else {
            const { name, value } = event.target;
            switch (name) {
                case "username":
                    setUsername(value);
                    break;
                case "firstname":
                    setFirstname(value);
                    break;
                case "lastname":
                    setLastname(value);
                    break;
                case "password1":
                    setPassword1(value);
                    break;
                case "password2":
                    setPassword2(value);
                    break;
                case "email":
                    setEmail(value);
                    break;
                case "phoneNumber":
                    setPhoneNumber(value);
                    break;
                case "adminLevel":
                    setAdminLevel(value);
                    break;
                case "description":
                    setDescription(value);
                    break;
                default:
                    break;
            }
            setSpaceWarningShow(false);
        }
    };

    const onSelectAdminLevel = (adminLevel) => {
        setAdminLevel(adminLevel);
    };

    const onHubNodeSelected = (node) => {
        Logger.debug(node);
        setHubNodeId(node.id);
    };

    const submitHandler = (event) => {
        event.preventDefault();
        if (!isCreatingUser) {
            saveHubUser();
        }
    };

    const createHubUser = async () => {
        // To prevent double-create:
        setIsCreatingUser(true);
        var createUserRequest = {
            userName: username,
            firstName: !!firstname ? firstname : null,
            lastName: !!lastname ? lastname : null,
            password: password1,
            email: email,
            phoneNumber: !!phoneNumber ? phoneNumber : null,
            description: !!description ? description : null,
            isReader: false,
            defaultCollectionId: null,
            defaultAccessMask: null,
            readRate: null,
            featureCode: null,
            identityGroup: null,
            adminLevel: adminLevel,
        };
        //createUserRequest.identityGroup = this.convertUserRoleIdToStr();
        try {
            var response = await hubApi.createHubUser(hubNodeId, createUserRequest);
            if (response.ok) {
                Logger.debug(`user created.`);
                props.onFormSubmitted();
            } else {
                Logger.error(`Create new hub user failed, response=.`, response);
                let error = await response.json();

                setFormError(`Failed: ${error.message}`);
                setIsStatusEnabled(true);
                setIsCreatingUser(false);
            }
        } catch (error) {
            setFormError(`Failed: ${error.message}`);
            setIsStatusEnabled(true);
            setIsCreatingUser(false);
        }
    };

    const updateHubUser = async (user) => {
        // To prevent double-create:
        setIsCreatingUser(true);
        var request = {
            userName: username,
            firstName: !!firstname ? firstname : null,
            lastName: !!lastname ? lastname : null,
            email: email,
            phoneNumber: !!phoneNumber ? phoneNumber : null,
            description: !!description ? description : null,
            hubNodeId: hubNodeId,
        };
        try {
            var updateUserInfoResponse = await hubApi.updateHubUser(user.id, request);
            var updateUserRoleResponse = await hubApi.updateHubUserRole(user.userGuid, adminLevel);

            // Reload user authority:
            let sub = authorizeService._user.profile.sub;
            const token = authorizeService._user.access_token;
            await authorizeService.loadAllUsers(token, sub);

            var errorAry = [];
            if (updateUserInfoResponse.ok && updateUserRoleResponse.ok) {
                Logger.debug(`user updated.`);
                props.onFormSubmitted();
            }

            if (!updateUserInfoResponse.ok) {
                Logger.error(`Updating hub user failed, response=.`, updateUserInfoResponse);
                errorAry.push(await updateUserInfoResponse.json());
            }
            if (!updateUserRoleResponse.ok) {
                Logger.error(`Updating hub user role failed, response=.`, updateUserRoleResponse);
                errorAry.push(await updateUserRoleResponse.json());
            }
            if (errorAry.length > 0) {
                throw new Error(
                    `Failed: ${errorAry.map((error, index) => {
                        return error.message + (index < errorAry.length - 1 ? " and " : "");
                    })}`
                );
            }
        } catch (error) {
            setFormError(`Failed: ${error.message}`);
            setIsStatusEnabled(true);
            setIsCreatingUser(false);
        }
    };

    const saveHubUser = async () => {
        if (!!user.LoginName) {
            await updateHubUser(user);
        } else {
            await createHubUser();
        }
    };


    let isUpdateUser = !!user.LoginName;

    return (
        <HubUserFormEx
            nodeId={hubNodeId}
            username={username}
            nodes={user.nodes}
            isUpdateUser={isUpdateUser}
            password1={password1}
            password2={password2}
            firstName={firstname}
            lastName={lastname}
            email={email}
            phoneNumber={phoneNumber}
            description={description}
            formError={formError}
            adminLevel={adminLevel}
            userType={user.userType}
            onChange={onChange}
            onHubNodeSelected={onHubNodeSelected}
            onSelectAdminLevel={onSelectAdminLevel}
            onSubmit={submitHandler}
        />
    );
}
