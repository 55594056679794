import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Modal, ListGroup, Popover, Overlay, Pagination, Spinner } from "react-bootstrap";
import "styles/widgets/FolderSecurityForm.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCheck, faSave, faUser, faUsers, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import authService from 'components/api-authorization/AuthorizeService';
import * as Logger from 'loglevel';
import folderApi from '../../../util/FolderApi'
import { interpretSecurityWord, convertToSecurityWord } from '../../post-authorization/libraries/DataHandler';


export function FolderPermissionsTable(props) {
    const [permissions, setPermissions] = useState(null);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showConfirmSave, setShowConfirmSave] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [formError, setFormError] = useState("");
    const [allUsers, setAllUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 7; // Number of rows per page

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate the range of rows to display on the current page
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedPermissions = (permissions || []).slice(startIndex, endIndex);

    const saveButtonRef = useRef(null);
    const trashButtonRef = useRef(null);


    useEffect(() => {
        const setData = async () => {
            const interpretedData = props.data.map((item) => ({
                ...item,
                permissions: interpretSecurityWord(item.securityWord),
            }));

            interpretedData.sort((a, b) => {
                if (a.principal.isSecurityGroup && !b.principal.isSecurityGroup) {
                    return -1;
                }
                if (!a.principal.isSecurityGroup && b.principal.isSecurityGroup) {
                    return 1;
                }
                return a.principal.name.localeCompare(b.principal.name);
            });

            await setPermissions(interpretedData);
        };

        setData();

        return () => {
            setFormError("");
        };


    }, []);




    const handlePermissionChange = (user, field, event) => {
        event.preventDefault();

        let updatePermissions = [...permissions];
        var index = permissions.findIndex((p) => p.principalId === user.principalId);
        updatePermissions[index].permissions[field] = !updatePermissions[index].permissions[field];
        setPermissions(updatePermissions);
    };

    const handleSave = async (user) => {
        if (props.editableRow && user.permissions && props.editableRow.principalId === user.principalId) {
            const newUser = {
                folderId: props.folderData.id,
                principalId: user.principalId,
                securityWord: convertToSecurityWord(user.permissions),
            };

            const allPermissionsFalse = Object.values(user.permissions).every((permission) => permission === false);

            if (!allPermissionsFalse) {
                let savedPermission;
                if (selectedUser && user.principalId === selectedUser.principalId) {
                    savedPermission = await folderApi.savePermission(newUser);
                    if (!savedPermission) {
                        setPermissions((prevPermissions) =>
                            prevPermissions.filter((permission) => permission.principalId !== user.principalId)
                        );
                    }
                    setSelectedUser(null);
                } else {
                    savedPermission =
                        props.editableRow.securityWord !== newUser.securityWord ? await folderApi.updatePermission(newUser) : null;
                    if (savedPermission) {
                        setPermissions((prevPermissions) =>
                            prevPermissions.map((permission) =>
                                permission.principalId === user.principalId
                                    ? { ...permission, securityWord: newUser.securityWord }
                                    : permission
                            )
                        );
                    } else {
                        setPermissions((prevPermissions) =>
                            prevPermissions.map((permission) =>
                                permission.principalId === user.principalId
                                    ? {
                                        ...permission,
                                        permissions: interpretSecurityWord(props.editableRow.securityWord),
                                    }
                                    : permission
                            )
                        );
                    }
                }

                setSelectedUser(null);
                props.setEditableRow(null);
                setShowConfirmSave(false);
            } else {
                setFormError("Please add some permissions before saving.");
            }
        }
    };



    const handleDelete = async (userToDelete) => {

        var deletedPermission = await folderApi.deletePermission(props.folderData.id, userToDelete.principalId);

        if (deletedPermission) {
            const newPermissions = permissions.filter((user) => user.principalId !== userToDelete.principalId);
            setPermissions(newPermissions);
        }
        props.setEditableRow(null);
        setSelectedUser(null);
        setShowConfirmDelete(false);
    };

    const handleEdit = (user) => {
        if (!!props.editableRow && props.editableRow.principalId === user.principalId) {
            props.setEditableRow(null);
        } else {
            const updatedEditableRow = { ...user };
            props.setEditableRow(updatedEditableRow);
        }
    };

    const handleClose = async (event) => {
        // event.preventDefault();

        setShowAddUserModal(false);

    }



    const addBtnOpen = async () => {
        const usersForSecPermissions = await folderApi.getAvailableUsers();
        const filteredUsers = usersForSecPermissions.filter(
            (user) => !permissions.some((permission) => permission.principalId === user.principalId)
        );

        // Sort filteredUsers by isSecurityGroup and then by name alphabetically
        filteredUsers.sort((a, b) => {
            if (a.principal.isSecurityGroup && !b.principal.isSecurityGroup) {
                return -1;
            }
            if (!a.principal.isSecurityGroup && b.principal.isSecurityGroup) {
                return 1;
            }
            return a.principal.name.localeCompare(b.principal.name);
        });

        setAllUsers(filteredUsers);
        setSelectedUser(null);
        setShowAddUserModal(true);
    };


    const handleAddUser = () => {
        const newUser = {
            ...selectedUser,
            permissions: {
                read: false,
                create: false,
                modify: false,
                delete: false,
                reorder: false,
            },
        };

        // Set the currentPage state to the last page
        const lastPage = Math.ceil((permissions.length + 1) / rowsPerPage);
        setCurrentPage(lastPage);

        setPermissions((prevPermissions) => [...prevPermissions, newUser]);
        setAllUsers(null);
        setShowAddUserModal(false);
        props.setEditableRow(newUser);
    };


    if (permissions === null) {
        return <Spinner animation="border" role="status" />;
    }


    const generatePermissionTd = (user, field) => {
        if (!!props.editableRow && props.editableRow.principalId === user.principalId) {
            return (
                <td key={field}>
                    <button
                        className="permBtn"
                        onClick={(event) => handlePermissionChange(user, field, event)}
                    >
                        {user.permissions[field] ? (
                            <FontAwesomeIcon icon={faCheck} />
                        ) : (
                            <span />
                        )}
                    </button>
                </td>
            );
        } else {
            return (
                <td key={field}>
                    {field === "name" ? (
                        user.principal.isSecurityGroup ? (
                            <FontAwesomeIcon icon={faUsers} />
                        ) : (
                            <FontAwesomeIcon icon={faUser} />
                        )
                    ) : user.permissions[field] ? (
                        <FontAwesomeIcon className="tickIcon" icon={faCheck} />
                    ) : (
                        <span></span>
                    )}
                </td>
            );
        }
    };

    return (
        <>
            <Button
                variant="primary"
                className="addUserPerm"
                disabled={!props.active || props.editableRow !== null}
                onClick={() => addBtnOpen()}
            >
                <FontAwesomeIcon icon={faPlus} /> Add
            </Button>
            <Table striped hover className="tableUserPerm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Read</th>
                        <th>Create</th>
                        <th>Modify</th>
                        <th>Delete</th>
                        {/*<th>Reorder</th>*/}
                        <th className="editBtnColumn"></th>
                    </tr>
                </thead>
                <tbody>
                    {!!permissions && permissions.length > 0 &&
                        displayedPermissions.map((user) => (
                            <tr key={user.principalId}>
                                <td>
                                    {user.principal.isSecurityGroup ? (
                                        <FontAwesomeIcon icon={faUsers} />
                                    ) : (
                                        <FontAwesomeIcon icon={faUser} />
                                    )}{" "}
                                    {user.principal.name}{" "}
                                </td>
                                {["read", "create", "modify", "delete"].map((field) =>
                                    generatePermissionTd(user, field) //change to user.permission
                                )}
                                <td>
                                    {!!props.editableRow && props.editableRow.principalId === user.principalId ? (
                                        <>
                                            <FontAwesomeIcon
                                                className="save-icon"
                                                onClick={() => setShowConfirmSave(true)}
                                                icon={faSave}
                                                ref={saveButtonRef}
                                            />
                                            <Overlay
                                                show={showConfirmSave}
                                                target={document.querySelector('.save-icon')}
                                                placement="bottom"
                                                containerPadding={10}
                                            >
                                                <Popover
                                                    className='confirmTooltip'
                                                    onClick={() => handleSave(user)}
                                                    onMouseLeave={() => setShowConfirmSave(false)}
                                                >
                                                    {' '}
                                                    Save changes? {' '}
                                                </Popover>
                                            </Overlay>
                                            <FontAwesomeIcon
                                                className="trash-icon"
                                                onClick={() => setShowConfirmDelete(true)}
                                                icon={faTrash}
                                                ref={trashButtonRef}
                                            />
                                            <Overlay
                                                show={showConfirmDelete}
                                                target={document.querySelector('.trash-icon')}
                                                placement="bottom"
                                                containerPadding={10}
                                            >
                                                <Popover
                                                    className='confirmTooltip'
                                                    onClick={() => handleDelete(user)}
                                                    onMouseLeave={() => setShowConfirmDelete(false)}
                                                >
                                                    {' '}
                                                    Confirm Delete?{' '}
                                                </Popover>
                                            </Overlay>
                                        </>
                                    ) : (
                                        <FontAwesomeIcon
                                            className="edit-button"
                                            onClick={props.editableRow ? null : () => handleEdit(user)}
                                            icon={faEdit}
                                            style={props.editableRow ? { opacity: 0.5, cursor: 'none' } : null}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Pagination className="pagUserPerm">
                <Pagination.Prev
                    className="paginationFirst"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                />
                {!!permissions && permissions.length > 0 &&
                    Array.from({ length: Math.ceil(permissions.length / rowsPerPage) }, (_, index) => (
                        <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))
                }
                <Pagination.Next
                    className='paginationLast'
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === Math.ceil((permissions.length + 1) / rowsPerPage)}
                />
            </Pagination>

            {formError ? (
                <div className='errorMsg' style={{ textAlign: "center" }}>{formError}</div>
            ) : (
                <></>
            )}
            <Modal className="userListModal" show={showAddUserModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Users/Groups</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup className="userListGroup">
                        {!!allUsers && allUsers.map((user) => (
                            <ListGroup.Item
                                className={`userListItem ${!!selectedUser && selectedUser.principalId === user.principalId ? 'selected' : ''}`}
                                key={user.principalId}
                                active={selectedUser && selectedUser.principalId === user.principalId}
                                onClick={() => setSelectedUser(user)}
                            >
                                {user.principal.isSecurityGroup ? (
                                    <FontAwesomeIcon icon={faUsers} />
                                ) : (
                                    <FontAwesomeIcon icon={faUser} />
                                )}{" "}
                                {user.principal.name}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={handleAddUser} >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
