import React from 'react';
import * as Logger from 'loglevel';
import { Row, Col, Card } from 'react-bootstrap';

//redux: 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as HubLogoActions from 'store/actions/HubLogoActions';
import * as HubSettingsActions from 'store/actions/HubSettingsActions';

import * as HubConstant from 'util/HubConstant';
import * as DataRenderHelper from 'util/DataRenderHelper'

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import update from 'immutability-helper';
import packageJson from '../../../../../package.json'

import 'styles/main-content/About.scss';

class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actualData: {}
        };
        this.sampleData = {
            "hubInfo": {
                "hubId": "Unknown",
                "name": "Unknown",
                "description": "Unknown",
                "version": "Unknown",
                "createTime": "Unknown",
                "nodes": [
                    { "type": -1, "count": -1 }
                ],
                "users": -1
            },
            "environment": {
                "hostEnvironment": "Unknown",
                "identityServer": "Unknown",
                "connectionString": "Unknown",
            },
            "runtime": {
                "stories": -1,
                "elememnts": -1,
                "mediaSize": -1
            }
        };
    }

    async componentDidMount() {
        if (!this.props.hubInfo) {
            await this.props.getHubInfo();
        }
        this.props.getHubEndpoints()
        this.props.setActivePageName({ singular: "About", plural: "About", icon: faQuestionCircle });
        await this.initializeHubInfo();
    }

    async initializeHubInfo() {
        if (!!this.props.hubInfo) {
            var idpUrl = "";
            if (!!this.props.hubInfo.environment) {
                idpUrl = this.props.hubInfo.environment.identityServer;
                await this.props.getIdpInfo(idpUrl);
            }
            this.setState({ actualData: this.props.hubInfo });
        } else {
            this.setState({ actualData: this.sampleData });
        }
    }

    render() {
        return (
            <div className="About">
                <Row>
                    <Col xs="4">
                        <div style={{maxWidth: "300px"}}>
                            {
                                (!!this.props.logoObj) ?
                                    <img className="AboutLogo" src={this.props.logoObj} alt="Logo" />
                                    :
                                    <img className="AboutLogo" src="/images/HubBrandingDefault-1.2-Grey.png" alt="Logo" />
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>ABOUT  THIS NEWSHUB</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="6" className="HubCol">
                                        {DataRenderHelper.renderHubInfo([
                                            { key: "name", title: "Hub Name", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "description", title: "Description", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "createTime", title: "Hub Installed", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            {
                                                title: "Hub URL(s)", 
                                                type: HubConstant.HUB_INFO_TYPE.CUSTOMIZED,
                                                val: this.props.hubEndpoints
                                            }
                                        ], this.state.actualData)}
                                    </Col>
                                    <Col xs="6">
                                        {DataRenderHelper.renderHubInfo([
                                            { key: "hubId", title: "Hub ID", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "clientVersion", title: "Client Version", type: HubConstant.HUB_INFO_TYPE.GENERAL },
                                            { key: "appVersion", title: "API Version", type: HubConstant.HUB_INFO_TYPE.GENERAL }
                                        ], this.state.actualData)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>LICENSING DETAILS</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="12">
                                        {DataRenderHelper.renderHubInfo([
                                            { key: "expireTime", title: "Licensed Until End of", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "identityServer", title: "Identity Server", type: HubConstant.HUB_INFO_TYPE.ENVIRONMENT },
                                            { key: "idpVersion", title: "Identity Server Version", type: HubConstant.HUB_INFO_TYPE.GENERAL }
                                        ], this.state.actualData)}

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapLogoAndSettingStatesToProps = (state) => {
    return {
        logoObj: state.hubConfig.hubLogo.logoObj,
        hubEndpoints: state.hubConfig.hubSettings.hubEndpoints,
        hubInfo: state.hubConfig.hubSettings.hubInfo
    }
}

export default withRouter(connect(
    mapLogoAndSettingStatesToProps,
    dispatch => bindActionCreators({ ...HubSettingsActions.mapSettingsDispatcherToProps, ...HubLogoActions.mapLogoDispatcherToProps }, dispatch)
)(About));
